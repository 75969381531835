import { Layout, LayoutSpacer, LayoutCol, LayoutRow } from '../components/Layout';
import Button from 'react-bootstrap/Button';

import React, { useState } from 'react';
import { GalleryImage, GalleryCard, GalleryGame, GalleryStory, GalleryStoryItem, GalleryVideo, Item } from '../components/GalleryCards';
import { Line } from '../components/PageCard';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFilm, faGamepad, faImage } from '@fortawesome/free-solid-svg-icons';

export default function Portfolio(props) {
    const [showComponent, setShowComponent] = useState();

    const setShow = (element) => {
        setShowComponent(element);
        window?.scrollTo(0, 0);
    }

    return (
        <Layout classname="test">
            <LayoutSpacer />

            {showComponent}
            
            <LayoutRow>
                {/*Among us*/}<GalleryCard title={<><II/> Among us</>}
                    thumbnail={"../Images/among us t.png"}
                    call={() => setShow(<GalleryImage
                        body={"A render I did in about 30 minutes, when the game had just dome out."}
                        image={"../Images/among us.png" }
                    />)}/>
                {/*Black hole*/}<GalleryCard title={<><VI /> Black hole</>}
                    thumbnail={"../Images/Black hole t.png"}
                    call={() => setShow(<GalleryVideo
                        body={"One of the only things I have ever made using Blender, that I feel is good enoght to show"}
                        video={"../Images/Black hole.mp4"}
                    />)}/>
                {/*Medallion*/}<GalleryCard title={<><II /> Medallion </>}
                    thumbnail={"../Images/fur_medallion t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"One of my firs atempts at using a PBR render workflow, with Nvidia's Mental Ray"}
                        image={"../Images/fur_medallion_02.png"}
                    />)}/>
                {/*Skull hound*/}<GalleryCard title={<><II /> Skull hound</>}
                    thumbnail={"../Images/hellie_full t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"A gift done for a friend, and as a learing experians. It incoborates a 3D scaned skull, MASH nodes to make the visulicer, and a bit or tradisonal modleing. rendered with Nvidia's Mental Ray "}
                        image={"../Images/hellie_full_02.png"}
                    />)}/>
                {/*Paw medallion*/}<GalleryCard title={<><II /> Paw medallion</>}
                    thumbnail={"../Images/paw thige t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"The final thing I did using Mental Ray, before it was retiered. Wanting to ecmerament with diffrent color intractions and transparent glass"}
                        image={"../Images/paw thige 01.png"}
                    />)}/>
                {/*Kiwi sitting*/}<GalleryCard title={<><II /> Kiwi sitting</>}
                    thumbnail={"../Images/Kiwi 1 t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"The first render I did with my charetor Kiwi, and to really learn to use Arnold as my new primary render workflow. This is also the case with the two following images"}
                        image={"../Images/Kiwi 1.png"}
                    />)}/>
                {/*Kiwi*/}<GalleryCard title={<><II /> Kiwi</>}
                    thumbnail={"../Images/Kiwi 2 t.png"} 
                    call={() => setShow(<GalleryImage
                        body={" "}
                        image={"../Images/Kiwi 2.png"}
                    />)}/>
                {/*Kiwi back*/}<GalleryCard title={<><II /> Kiwi back</>}
                    thumbnail={"../Images/Kiwi 3 t.png"} 
                    call={() => setShow(<GalleryImage
                        body={" "}
                        image={"../Images/Kiwi 3.png"}
                    />)}/>
                {/*Doggo*/}<GalleryCard title={<><II /> Doggo</>}
                    thumbnail={"../Images/dog t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"One of my first atempts at a styelized render"}
                        image={"../Images/dog_01.png"}
                    />)}/>
                {/*Submorin*/}<GalleryCard title={<><II /> Submorin</>}
                    thumbnail={"../Images/Submorin t.png"} 
                    call={() => setShow(<GalleryImage
                        body={"I wanted to play around with a toon shader and some outlining"}
                        image={"../Images/Submorin.png"}
                    />)}/>
                {/*Kiwi tunrtable*/}<GalleryCard title={<><VI /> Kiwi tunrtable</>}
                    thumbnail={"../Images/Kiwi shooting 360 t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"The first thing I did after getting a new graphics card that allowed me to do longer renders in a resenable timeframe."}
                        video={"../Images/Kiwi shooting 360.mp4"}
                    />)}/>
                {/*Kiwi aniation*/}<GalleryCard title={<><VI /> Kiwi aniation</>}
                    thumbnail={"../Images/KIwi shooting t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"I wanted to get better at annatiobn and building contrl rigs, so i set myself that goal, and this is the result "}
                        video={"../Images/Kiwi sitting shoot.mp4"}
                    />)}/>
                {/*Zenith refrence*/}<GalleryCard title={<><VI /> Zenith refrence</>}
                    thumbnail={"../Images/Zenith 360 t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"A turnetable showcase of my charetor Zenith, with a bit of infomation and color codes for ease of use"}
                        video={"../Images/Zenith 360 ref.mp4"}
                    />)}/>
                {/*Zicco refrence*/}<GalleryCard title={<><VI /> Zicco refrence</>}
                    thumbnail={"../Images/Zicco 360 t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"A turnetable showcase of my charetor Zicco, with a bit of infomation and color codes for ease of use"}
                        video={"../Images/Zicco 360 ref.mp4"}
                    />)}/>
                {/*Kiwi refrence*/}<GalleryCard title={<><VI /> Kiwi refrence</>}
                    thumbnail={"../Images/Kiwi 360 t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"A turnetable showcase of my charetor Kiwi, with a bit of infomation and color codes for ease of use"}
                        video={"../Images/Kiwi 360 ref.mp4"}
                    />)}/>
                {/*Cassini refrence*/}<GalleryCard title={<><VI /> Cassini refrence</>}
                    thumbnail={"../Images/Cassini 360 t.png"} 
                    call={() => setShow(<GalleryVideo
                        body={"A turnetable showcase of my charetor Cassini, with a bit of infomation and color codes for ease of use"}
                        video={"../Images/Cassini 360 ref.mp4"}
                    />)}/>
                {/*Land lords*/}<GalleryCard title={<><GI /> Land lords</>}
                    thumbnail={"../Images/Land lords t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Land lords 01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Land lords 02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Land lords 03.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Land lords 04.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"LandLords"}
                        body={"Controle your colenyest to gather materials, build, explore, and fight enemies. Featuring 7 different levels for you and your colony to conker, etch level shows of new mechanics and features."}
                        role={"As this is a free time project made with a friend, we both have lots of roles, my main one being modeling, texturing and QA. but i also did a bit of programing"}
                        link={"https://rasmusdas.itch.io/land-lords"}
                    />)} />
                {/*Distortion*/}<GalleryCard title={<><GI /> Distortion</>}
                    thumbnail={"../Images/Distortion t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Distortion_01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Distortion_02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Distortion_03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Distortion"}
                        body={"This was made as part of a gamejam (DM in game development 20119) and is the first 2D game i have made. The point is to use your own dead bodys to make platforms, so you can jump over spikes, the game also has different endings depending on how much you die."}
                        role = {"My main role was as a level designer and helping our artist, who had never made a game before"}
                        link={"https://rasmusdas.itch.io/distortion"}
                    />)} />
                {/*Prismania*/}<GalleryCard title={<><GI /> Prismania</>}
                    thumbnail={"../Images/Prismania t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Prismania_01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Prismania_02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Prismania_03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Prismania"}
                        body={"This was the first game I was part of making, it was made as part of a gamejam and was feter developed at a bootcamp later. The game is a puzzle game where you are trying my match colored lasers with receivers using mirrors"}
                        role={"It was made by a team of 4, my main role was as an asset designer"}
                        link={"https://jaco8485.itch.io/prismania"}
                    />)} />
                {/*SciFi game*/}<GalleryCard title={<><GI /> SciFi game</>}
                    thumbnail={"../Images/SiFigame t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/SiFigame.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/SiFigame_02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/SiFigame_03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"SciFi game"}
                        body={"This was made as a school project and is an endless runner type of game and was mainly to look at efficient ways of making such a game. The game gets faster as you get feather in to it, as a way to make it harder."}
                        role={"My main role was in concept art and background research, I also made the spaceship"}
                        link={"https://zicco.itch.io/scifi-game"}
                    />)} />
                {/*Split-screen VR*/}<GalleryCard title={<><GI /> Split-screen VR</>}
                    thumbnail={"../Images/VRgame t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/VRgame_01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/VRgame_02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/VRgame_03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Split-screen VR game"}
                        body={"This was the first time developing for VR, it is a co-op splitscreen game, with one in VR and one on desktop. The point is to try rob a bank vault. You have to communicate as the VR persone has to give info to the desktop persone who has to hack dores and cameras to help get the gold at the end."}
                        role={"My main role was as an asset designer, level designer and playtester"}
                        link={"https://jaco8485.itch.io/lets-robber-bank"}
                    />)} />
                {/*Dungeon game*/}<GalleryCard title={<><GI /> Dungeon game</>}
                    thumbnail={"../Images/Dungeongame t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Dungeongame_01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Dungeongame_02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Dungeongame_03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Dungeon game"}
                        body={""}
                        role={" missing itch page"}
                        link={"https://zicco.itch.io/ddu"}
                    />)} />
                {/*Fishy Business*/}<GalleryCard title={<><GI /> Fishy Business</>}
                    thumbnail={"../Images/Fishy Business t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Fishy Business 01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Fishy Business 02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Fishy Business 02.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Fishy Business"}
                        body={"A party game where you fight with your friends to gather the most fish, and become the ultimate penguin. Play with 2-4 people in a fast pace party game."}
                        role={""}
                        link={"https://rasmusdas.itch.io/fishy-business"}
                    />)} />
                {/*Regifted*/}<GalleryCard title={<><GI /> Regifted</>}
                    thumbnail={"../Images/Regifted t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Regifted 01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Regifted 02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/Regifted 03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"Regifted"}
                        body={"You are a professional home invader, stealing gifts in 3 different levels, while trying to avoid getting spotted by the people living there."}
                        role={""}
                        link={"https://zicco.itch.io/regifted"}
                    />)}/>
                {/*What if key*/}<GalleryCard title={<><GI /> What if key</>}
                    thumbnail={"../Images/What if key t.png"}
                    call={() => setShow(<GalleryGame
                        content={
                            <Carousel className="border-primary border" fade >
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/What if key 01.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/What if key 02.png"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        alt={""}
                                        className="d-block w-100"
                                        src="../images/What if key 03.png"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        }
                        title={"What if key"}
                        body={""}
                        role={""}
                        link={"https://zicco.itch.io/dm-gamejam-2021-nov"}
                    />)}/>          
                {/*spacesuit*/}<GalleryCard title={<><SI /> Space suit</>}
                    thumbnail={"../Images/SpaceSuit t.png"}
                    call={() => setShow(<GalleryStory
                        title={"VRchat spacesuit"}
                    >
                        <GalleryImage
                            image={"../Images/suit pic 3.png"}
                        />
                        <GalleryStoryItem
                            top
                            textlenth={3}
                            body={<p>The space-roo is here and ready to take flight! This has been a passion project of mine lately, hugely inspired by Elite Dangerous and its holo suits. I wanted to make one for VRchat. So I give you all the space kanga. It features a range of different materials all carefully designed to look good in as much of VRchat as possible. The helmet and jetpack can be freely toggled in game if you don't want to use them. All the suit's colors can easily be edited using the provided source files, so you can make it your own. And if you don't what to make your own, there is also a public version that you can find in the kanga world.
                            </p>}
                        />
                        <GalleryStoryItem
                            textlenth={3}
                            body={<p>The base kangaroo was made by Spaghet and you need to buy that separately. The helmet, jetpack, and suit were all made by me and are subject to both my and Spaghet's TOS meaning that you can modify this however you want. What you can't do is claim any of this work as your own nor can you remove the watermarks/ads placed in the assets. Reselling any of my work is also not allowed.
                            </p>}
                        />
                        <GalleryStoryItem
                            textlenth={2}
                            body={<p>Follow these two steps to get your own kangaroo in the suit. First, you need to import your textures into the space kangaroo Substance Painter project. To do this, simply drag and drop them anywhere in Substance Painter, or use File > Import resources and find your textures there. When imported, set them all to texture and import them to the current session, as shown.
                            </p>}
                            item1={<Item image={"../Images/SP help 1.jpg"} />}
                        />
                        <GalleryStoryItem
                            textlenth={2}
                            right
                            body={<><p>When you see them in your shelf, go to Base kanga > Your textures. Drag them into the five slots which are already populated, overriding the previous settings. You do not need to fill all five, for example, if you lack an emissive map (glow) you can disable this channel. It's important that you place your specular smoothness map in both slots asking for it in order to get the best result.
                            </p>
                            <Line />
                            <p>If you wish to edit the looks of the suit you have two options. If you would just like to change the color you can play with the folder named "Edit suit colors". This lets you change the suit colors, without a lot of experience in Substance Painter. If you want to edit the patterns or anything else you can dig down and edit in my layers. If you edit the layers you should remember to disable the "Edit suit colors" folder, as it will override color changes you make in the layer stack.
                            </p>
                            <Line />
                                <p>You have a few options in Unity. If you wish to make a standalone version, there is already a scene set up with everything you need. Simply load the scene found in the Space kanga folder and you are good to go. If you wish to apply the suit to an existing avatar, place the helmet under the head bone and the jetpack under the chest bone. When you are happy with your placement disable both objects, and link the animations provided to toggle them with Avatar 3.0. Remember that you will need to import the VRChat SDK Avatar 3.0, the dynamic bone plug-in, and the kangaroo base, in that order, before importing my spacesuit. The suit itself does not come with these plugins.
                            </p>
                            </>}
                            item1={<Item image={"../Images/SP help 2.jpg"} />}
                        />
                        <LayoutRow>
                            <Line />
                            <LayoutCol w={4}>
                                <LayoutRow>
                                    <div className="d-grid gap-2">
                                        <Button rel="nofollow noopener noreferrer" size="lg" href={"https://www.vrcarena.com/assets/7NGpFnJ6iKfnzY9XiTB4"} variant="primary">Download Base kagaroo</Button>
                                    </div>
                                </LayoutRow>
                            </LayoutCol>
                            <LayoutCol w={4}>
                                <LayoutRow>
                                    <div className="d-grid gap-2">
                                        <Button rel="nofollow noopener noreferrer" size="lg" href={"../Files/Space kanga substance.zip"} variant="primary">Download Substance Painter file</Button>
                                    </div>
                                </LayoutRow>
                            </LayoutCol>
                            <LayoutCol w={4}>
                                <LayoutRow>
                                    <div className="d-grid gap-2">
                                        <Button rel="nofollow noopener noreferrer" size="lg" href={"../Files/Space kanga unity.zip"} variant="primary">Download Unity package</Button>
                                    </div>
                                </LayoutRow>
                            </LayoutCol>
                        </LayoutRow>
                        <GalleryStoryItem
                            item1={<Item image={"../Images/suit pic 1.png"} />}
                            item2={<Item image={"../Images/suit pic 2.png"} />}
                            item3={<Item image={"../Images/suit pic 4.png"} />}
                        />
                    </GalleryStory>)} />
                {/*Saturn v rocket*/}<GalleryCard title={<><SI /> Space suit</>}
                    thumbnail={"../Images/Saturn v t.png"}
                    call={() => setShow(<GalleryStory
                        title={"3D printed saturn V rocket"}
                    >
                        <GalleryImage
                            image={"../Images/Saturn v 01.png"}
                        />
                        <GalleryStoryItem
                            top
                            textlenth={3}
                            body={<p></p>}
                        />
                        <GalleryStoryItem
                            item1={<Item image={"../Images/Saturn v 02.png"} />}
                            item2={<Item image={"../Images/Saturn v 03.png"} />}
                            item3={<Item image={"../Images/Saturn v 04.png"} />}
                        />
                    </GalleryStory>)} />
            </LayoutRow>
        </Layout>
    );
}
export function VI() {
    return (
        <FontAwesomeIcon icon={faFilm} />
    );
}
export function II() {
    return (
        <FontAwesomeIcon icon={faImage} />
    );
}
export function GI() {
    return (
        <FontAwesomeIcon icon={faGamepad} />
    );
}
export function SI() {
    return (
        <FontAwesomeIcon icon={faBook} />
    );
}