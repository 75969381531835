import { Layout } from './Layout';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { faGithub, faItchIo, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import FA from '../components/FA';
import { Link } from 'react-router-dom';

export default function NavBar(props) {
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Layout>
                <Navbar.Brand as="span">
                    <Link to="/">Home</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as="span">
                            <Link to="/About">About</Link>
                        </Nav.Link>
                        <Nav.Link as="span">
                            <Link to="/Portfolio">Portfolio</Link>
                        </Nav.Link>
                        <NavDropdown title="Commission" id="collasible-nav-dropdown">
                            <NavDropdown.Item as="span">Get a qoute</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Pricing</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Terms of service</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <NavDropdown title="Other platformes" id="collasible-nav-dropdown">
                            <NavDropdown.Item rel="nofollow noopener noreferrer" href="https://twitter.com/ziccoroo"><FA icon={faTwitter}/> Twitter</NavDropdown.Item>
                            <NavDropdown.Item rel="nofollow noopener noreferrer" href="https://daniel-sindrup.itch.io/"><FA icon={faItchIo} /> Itch</NavDropdown.Item>
                            <NavDropdown.Item rel="nofollow noopener noreferrer" href="https://github.com/ziccoroo"><FA icon={faGithub} /> Github</NavDropdown.Item>
                            <NavDropdown.Item rel="nofollow noopener noreferrer" href="https://www.linkedin.com/in/daniel-sindrup-4794791a4/"> <FA icon={faLinkedin} /> Linkedin</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Layout>
        </Navbar>
    );
}