import Button from 'react-bootstrap/Button';
import { LayoutCol, LayoutRow } from '../components/Layout';
import { BaseCard, Line, PageCard, PageCardFlex } from './PageCard';
import Card from 'react-bootstrap/Card';
import FA from '../components/FA';
import {faItchIo} from '@fortawesome/free-brands-svg-icons';

export function GalleryCard(props) {
    return (
        <LayoutCol w={2}>
            <BaseCard>
                <Button variant="dark" className="p-0" onClick={props.call}>
                    <Card.Header>
                        <Card.Title style={{ textAlign: "center" }}>{props.title}</Card.Title>
                    </Card.Header>
                    <Card.Img variant="bottom" src={props.thumbnail} />
                </Button>
            </BaseCard>
        </LayoutCol>
    );
}
export function GalleryImage(props) {
    return (
        <LayoutRow>
            <LayoutCol>
                <PageCard
                    title={props.title}
                    body={props.body}
                    image={props.image}
                />
            </LayoutCol>
        </LayoutRow>
    );
}
export function GalleryVideo(props) {
    return (
        <LayoutRow>
            <LayoutCol>
                <PageCard
                    title={props.title}
                    body={props.body}
                    video={props.video}
                />
            </LayoutCol>
        </LayoutRow>
    );
}
export function GalleryStory(props) {
    return (
        <LayoutRow>
            <LayoutCol>
                <PageCardFlex
                    title={props.title}
                >
                    {props.children}
                </PageCardFlex>
            </LayoutCol>
        </LayoutRow>
    );
}

export function GalleryStoryItem(props) {
    return (
        <LayoutRow>
            {props.top ? <></> : (
                <div className={"mt-3"}><Line /></div>
            )}

            {props.right ? <></> : <>
                {props.item1}
                {props.item2}
                {props.item3}
            </>}
            <LayoutCol w={props.textlenth * 4}>
                <LayoutRow>
                    {props.body}
                </LayoutRow>
            </LayoutCol>
            {props.right ? <>
                {props.item1}
                {props.item2}
                {props.item3}
            </> : <></>}
        </LayoutRow>
    );
}
export function Item(props) {
    return (
        <LayoutCol w={4}>

            {props.image ? (
                <Card.Img className="border border-primary" src={props.image} />
            ) : <></>}

            {props.video ? (
                <div className="border border-primary">
                    <video autoPlay loop controls muted className="d-block w-100"
                        src={props.video}
                    />
                </div>
            ) : <></>}

            {props.flex ? (
                <div className="border border-primary">
                    {props.flex}
                </div>
            ) : <></>}

        </LayoutCol>
    );
}
export function GalleryGame(props) {
    return (
        <LayoutRow>
            <LayoutCol>
                <PageCardFlex title={props.title}>
                    <LayoutRow>
                        <LayoutCol w={8}>
                            {props.content}
                        </LayoutCol>
                        <LayoutCol w={4}>
                            <LayoutRow>
                                <div className="mb-3 d-grid gap-2">
                                    <Button rel="nofollow noopener noreferrer" size="lg" href={props.link} variant="primary"><FA icon={faItchIo} size={"fa-1x"} /> Download from itch</Button>
                                </div>
                            </LayoutRow>
                            <LayoutRow>
                                <p >{props.body}</p>
                                <Line/>
                                <p >{props.role}</p>
                            </LayoutRow>
                        </LayoutCol>
                    </LayoutRow>
                </PageCardFlex>
            </LayoutCol>
        </LayoutRow>
    );
}