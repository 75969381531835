import { Layout, LayoutSpacer, LayoutCol, LayoutRow } from '../components/Layout';
import { Line, PageCard, PageCardFlex } from '../components/PageCard';
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function About(props) {

    return (
        <Layout>
            <LayoutSpacer />
            <LayoutRow>
                <LayoutCol w={8}>
                    <LayoutRow>
                        <LayoutCol>
                            <PageCard
                                title="General information"
                                body="I love making games and challenging myself with new projects and learning new skills. I have an HTX from ZBC Ringsted, with game development, most of the games seen in my portfolio were made during my time as a student. I currently work on smaller commissions, while looking for more stable full/part-time work. I am also working as a part of Project island, a revival project for the old LEGO island games. Where I do model and asset optimization"
                            />
                        </LayoutCol>
                    </LayoutRow>
                    <LayoutRow>
                        <LayoutCol w={6}>
                            <PageCard
                                title="Modeling"
                                body="I am a self taught modeler sins 2013 both in and of games, with Maya as my primary work environment. My strong suit is creating objects and environments, making rigs is also within my skill set, but I am not as experienced in it. I also have experience in making materials both in Maya and with Substance painter."
                            />
                            <PageCard
                                title="Game development"
                                body="I have been making games sins 2017, mostly with a group of friends for game jams and school projects.most of my experience is in unity, as it is the engine my games are developed in. I have also done modding for ARK survival evolved in Unreal engine 4 and for skyrim in Bethesda's creation engine."
                            />
                        </LayoutCol>
                        <LayoutCol w={6}>
                            <PageCard
                                title="Coding"
                                body="I am relatively new novice coda but I know how to write basic she sharp code HTML and CSS mostly for proof of concept or easy prototyping but of course always looking to get better. reading and understanding code is mostly not a problem To understand what other people have done should be simple"
                            />
                            <PageCard
                                title="Experience"
                                body="I have been working freelance for 1 and a half years, working on making custom game-ready assets, on a commission basis. Developing Land lords on the side, expanding the scope of the game, and developing it into more than a prototype. I also have experience working in large teams, as a modeler for Project island, working alongside other developers to optimize assets for the game."
                            />
                        </LayoutCol>
                    </LayoutRow>
                </LayoutCol>
                <LayoutCol w={4}>
                    <PageCardFlex title="Skill sets">
                        <Trow top={true} title="Maya" bar={80} />
                        <Trow title="Blender" bar={30} />
                        <Trow title="Mudbox" bar={20} />
                        <Trow title="Substance painter" bar={70} />
                        <Trow title="Unity" bar={80} />
                        <Trow title="Unreal" bar={40} />
                        <Trow title="Photoshop" bar={50} />
                    </PageCardFlex>
                </LayoutCol>
            </LayoutRow>
        </Layout>
    );
}
function Trow(props) {

    return (
        <div>
            {props.top ? (<div/>) : (
                <div className={"mt-4"}><Line /></div>
            )}

            <p>{props.title}</p>
            <ProgressBar className="bg-secondary border-primary border" now={props.bar} />
        </div>
    );
}