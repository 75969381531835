import { Layout, LayoutSpacer, LayoutCol, LayoutRow} from '../components/Layout';
import { PageCard, PageCardFlex } from '../components/PageCard';
import FA from '../components/FA';
import Button from 'react-bootstrap/Button';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub, faItchIo, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Carousel from 'react-bootstrap/Carousel';

export default function Index(props) {

    return (
        <Layout>
            <LayoutSpacer />
            <LayoutRow>
                <LayoutCol>
                    <LayoutRow>
                        <LayoutCol>
                            <PageCardFlex>
                                <Carousel fade>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="../images/Black hole.jpg"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="../images/Kiwi 1.png"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </PageCardFlex>
                        </LayoutCol>
                    </LayoutRow>
                    <LayoutRow>
                        <LayoutCol w={8}>
                            <PageCardFlex
                                title="test"
                            >
                                {/*https://www.npmjs.com/package/react-twitter-embed*/}
                                <TwitterTimelineEmbed
                                    onLoad={function noRefCheck() { }}
                                    options={{
                                        height: 400
                                    }}
                                    sourceType="collection"
                                    url="https://twitter.com/ziccoroo"
                                />
                            </PageCardFlex>
                        </LayoutCol>
                        <LayoutCol w={4}>
                            <PageCard
                                title="About"
                                body="I am a freelance modeler and texture artist who loves to experiment and learn new things, I sometimes make games for fun, but most of my time is spent making CG art."
                            />
                            <PageCardFlex title="Social" style={{ textAlign: "center" }}>
                                <OutgoingLink link={"https://zicco.itch.io/"} icon={faItchIo}/>
                                <OutgoingLink link={"https://twitter.com/ziccoroo"} icon={faTwitter}/>
                                <OutgoingLink link={"https://github.com/ziccoroo"} icon={faGithub}/>
                                <OutgoingLink link={"https://www.linkedin.com/in/daniel-sindrup-4794791a4/"} icon={faLinkedin}/>
                            </PageCardFlex>
                            <PageCardFlex title="Contact" style={{ textAlign: "center" }}>
                                <OutgoingLink link={"https://t.me/zicco"} icon={faPaperPlane}/>
                                <OutgoingLink link={"https://t.me/zicco"} icon={faDiscord}/>
                            </PageCardFlex>
                        </LayoutCol>
                    </LayoutRow>
                </LayoutCol>
            </LayoutRow>
        </Layout>
    );
}

function OutgoingLink(props) {
    return (
        <Button rel="nofollow noopener noreferrer" href={props.link} variant="link"><FA icon={props.icon} size={"fa-3x"} /></Button>
    );
}

