import Card from 'react-bootstrap/Card';

export function BaseCard(props) {
    return (
        <Card className="bg-dark border-primary mb-4 border">
            {props.children}
        </Card>
    );
}
export function Line(props) {
    return (
        <div><div className=" mb-3 border-primary border-bottom" /></div>
    );
}
export function PageCard(props) {
    return (
        <BaseCard>
            {props.title ? (
                <Card.Header>
                    <Card.Title style={{ textAlign: "center" }}>{props.title}</Card.Title>
                </Card.Header>
            ) : <></>}

            {props.body ? (
                <Card.Body>
                    <Card.Text style={props.style}>
                        {props.body}
                    </Card.Text>
                </Card.Body>
            ) : <></>}

            {props.image ? (
                <Card.Img variant="bottom" src={props.image} />
            ) : <></>}

            {props.video ? (
                <div className="square bg-primary rounded-bottom">
                    <video className="square bg-primary rounded-bottom" autoPlay loop controls muted className="d-block w-100"
                        src={props.video}
                    />
                </div>
            ) : <></>}

        </BaseCard>
    );
}

export function PageCardFlex(props) {
    return (
        <BaseCard>
            {props.title ? (
                <Card.Header>
                    <Card.Title style={{ textAlign: "center" }}>{props.title}</Card.Title>
                </Card.Header>
            ) : <></>}

            <Card.Body style={props.style}>
                {props.children}
            </Card.Body>
        </BaseCard>
    );
}
