import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function Layout(props) {
    return (
        <Container>
            {props.children}
        </Container>
    );
}
export function LayoutSpacer() {
    return (
        <Row classname="mt-4">
            <p></p>
        </Row>
    );
}
export function LayoutCol(props) {
    return (
        <Col sm={props.w}>
            {props.children}
        </Col>
    );
}
export function LayoutRow(props) {
    return (
        <Row>
            {props.children}
        </Row>
    );
}