import { createBrowserRouter } from 'react-router-dom';
import Index from './routes/Index';
import About from './routes/About';
import Portfolio from './routes/Portfolio';
import App from './App';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: '/',
                element: <Index />,
            },
            {
                path: '/Portfolio',
                element: <Portfolio />,
            },
            {
                path: '/About',
                element: <About />,
            },
        ],
    },
])